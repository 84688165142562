import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { Card, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, CardFooter, InputGroup, InputGroupAddon, InputGroupText, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Label } from '../../components/UI/Form/Label/Label';
import { toast, ToastContainer } from 'react-toastify';


class Qrcode extends React.Component {
    

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            location: '',
            date_start: '',
            date_end: '',
            data : [],
            urlPDF : '',
            numPage : 1
        }
    }

     onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({numPage: numPages})
    }



    componentDidMount() {
        fetch('https://system.ipbsciencetechnopark.com/api/qrcode').then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    data: result
                })
                console.log(this.state.data)
            },
            (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
        )
    }

    

    inputData = () => {
        console.log(this.state)
        
        const formData = new FormData();
        formData.append("location", this.state.location);
        formData.append("date_start", this.state.date_start);
        formData.append("date_end", this.state.date_end);

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/create_qrcode",
            formData
        ).then(response => {
            toast.success("Berhasil dihapus");
          window.location.href = '/dashboard/qrcode'
        })


    }

    printQr = (id) => {
        console.log(id);
        const formData = new FormData();
        formData.append('id', id);

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/get_qrcode",
            formData
        ).then(response => {
            console.log(response)
        })
        
    }

    DeletedQr = (id) => {
        // console.log(id);
        const formData = new FormData();
        formData.append('id', id);

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/deleted_qrcode",
            formData
        ).then(response => {
            toast.success("Berhasil dihapus");
          window.location.href = '/dashboard/qrcode'
        })
        
    }

    EditQr = (data) => {
        this.setState({location: data.location})
        
    }




    render() {
        return(

   


<div className="content">
    <form>
        <Row>
        <Col md={8} xs={12}>
        <Card className="card-user">
        <CardHeader>
            <h6>Input Qr Code</h6>
        </CardHeader>
        <CardBody>
        <Col md={12}>
        <Label for="name" required>Location <small>/ Lokasi</small></Label>
        <Input type="text" value={this.state.location} name="name" onChange={(event) => this.setState({ location: event.target.value })}></Input>
        </Col>

        <Col md={12}>
        <Label for="name" required>Start Time <small>/ Waktu Mulai</small></Label>
         <Input type="time" value={this.state.date_start} name="name" onChange={(event) => this.setState({ date_start: event.target.value })}></Input>
        </Col>

        <Col md={12}>
        <Label for="name" required>End Time <small>/ Waktu Berakhir</small></Label>
         <Input type="time" value={this.state.date_end} name="name" onChange={(event) => this.setState({ date_end: event.target.value })}></Input>
        </Col>
        </CardBody>

        <Button onClick={this.inputData} color="secondary">Save</Button>
            </Card>
        </Col>

        </Row>
        
        <Row>
            <Col xs={12}>
            <Card>
                <CardHeader>
                <h6>Qr Code</h6>
                </CardHeader>

            <CardBody>
            <Table responsive>
                    <thead className="text-primary">
                        <tr>
                            <th className="text-right">Location</th>
                            <th className="text-right">Date Start</th>
                            <th className="text-right">Date End</th>
                            <th className="text-right">Qr Code</th>
                            <th className="text-right">Option</th>
                        </tr>
                        
                    </thead>
                    <tbody>
                    {this.state.data && this.state.data.map((datas) => { 
                   return(
                    <tr>
                    <td>{datas.location}</td>
                    <td>{datas.date_start}</td>
                    <td>{datas.date_end}</td>
                    <td>
  <div className="content">
   <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
<QRCode
size={256}
style={{ height: "auto", maxWidth: "100%", width: "100%" }}
value={`{"location":"${datas.location}"}`}
viewBox={`0 0 256 256`}
/>

</div>
    </div>


                    </td>
                    <td>
                    <button onClick={(e) => this.DeletedQr(datas.id)}>Delete</button> | 
                
                        <a href={`https://system.ipbsciencetechnopark.com/api/get_qrcode/${datas.id}`}>Print QR</a> |
                        
                        {/* <button type="button" onClick={(e) => this.EditQr(datas)}>Edit</button> */}
                        </td>
                        
                </tr>

                   )
                
                })}
                        
                       

                    </tbody>

             </Table>
                {/* {this.state.urlPDF != ''?
                <Document file={this.state.urlPDF} onLoadSuccess={this.onDocumentLoadSuccess}>
                    <Page pageNumber={this.state.numPage} />
                </Document>: null
                } */}

            </CardBody>


            </Card>
            </Col>
            

        </Row>




    </form>

    </div>




        )
        
    }



}

export default Qrcode;


