import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Button,
    CardFooter,
    CardTitle,
    Row,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Col,
    Table
} from "reactstrap";
import { thead, tbody, tableAbsensi } from "../../variables/general";
import Stats from "../../components/Stats/Stats.jsx";
import { Label } from '../../components/UI/Form/Label/Label';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { ListUser } from '../../api/index';
// import { ListPayment, ConfirmPayment, WaitingPayment,RejurnalPayment } from '../../api/index'
import { toast, ToastContainer } from 'react-toastify';
import Moment from 'react-moment';
import 'moment/locale/id';
import Axios from 'axios';
import moment from 'moment'
import { parse } from 'querystring';
import SweetAlert2 from 'react-sweetalert2';

class ReportSecurity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            data: []
        }
    }

    componentDidMount() {
        fetch('https://system.ipbsciencetechnopark.com/api/repot_oto_security').then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    data: result.data
                })
            },
            (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
        )
    }

    HandleOtorisasi(id) {

      const formData = new FormData();
      formData.append("id", id);
      formData.append("name_supervisi", localStorage.getItem('name'));

      return Axios.post(
        "https://system.ipbsciencetechnopark.com/api/otorisasi",
        formData)
        .then(response => {
          toast.success("Otorisasi Berhasil");
          window.location.href = '/dashboard/reportsecurity'
          
        })

    }






render() {
    const { error, isLoaded, data } = this.state;
    
    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
            <div className="content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">User Absensi Security</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                <thead className="text-primary">
                    <tr>
                      {tableAbsensi.map((prop, key) => {
                        if (key === tableAbsensi.length - 1)
                          return (
                            <th key={key} className="text-right">
                              {prop}
                            </th>
                          );
                        return <th key={key}>{prop}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <td>nama</td>
                    </tr> */}
                    {data.map((datas) => { 
                      let color;
                      let date_absen = new Date(datas.date_absen)
                      let comperesionDate = date_absen.getHours() + ':' + date_absen.getMinutes() + ':' + date_absen.getSeconds()
                      
                      // console.log(comperesionDate);
                      // console.log(datas.date_end <= comperesionDate)
                      // if (Date.parse(`01/01/2022 ${datas.date_start}`) <= Date.parse(`01/01/2022 ${comperesionDate}`) && Date.parse(`01/01/2022 ${datas.date_end}`) >= Date.parse(`01/01/2022 ${comperesionDate}`)) {
                      //   color = 'white'
                      // } else {
                      //   color = 'yellow'
                      // }
                      return (
              <tr style={{backgroundColor:color}} key={datas.id}>
                <td>{datas.name_user}</td>
                <td>{datas.divisi}</td>
                <td>{datas.location}</td> 
                <td><img src={datas.photo_selfie} alt="new"/></td>
                <td><img src={datas.photo_tempat} alt="new"/></td>
                <td>{datas.date_absen}</td>
                <td>{datas.status_absen}</td>
                <td> 
                  
                  <button onClick={(e) => this.HandleOtorisasi(datas.id)}>otorisasi</button> 
                | <button>Delete</button> </td>
              </tr>
            )})}
                  </tbody>

                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
          <ul>
            
          </ul>
          </div>
        );
      }
}


}

export default ReportSecurity;