import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { Card, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, CardFooter, InputGroup, InputGroupAddon, InputGroupText, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Label } from '../../components/UI/Form/Label/Label';
import { toast, ToastContainer } from 'react-toastify';
import { Route, Switch, Redirect } from "react-router-dom";

class DivisiUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            id_user: '',
            divisi: '',
            data : []
        }
    }

    componentDidMount() {
        fetch('https://system.ipbsciencetechnopark.com/api/divisiuser').then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    data: result
                })
                console.log(this.state.data)
            },
            (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
        )
    }

    

    inputData = () => {
        console.log(this.state)
        
        const formData = new FormData();
        formData.append("id_user", this.state.id_user);
        formData.append("divisi", this.state.divisi);

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            console.log(response)
        })


    }

    inputBanquet = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'banquet');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputSecurity = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'security');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputFO = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'fo');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputDamri = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'damri');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputFacility = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'facility');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputHousekeeping = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'housekeeping');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputSupervisiSecurity = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'supervisi_security');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputSupervisiBanquet = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'supervisi_banquet');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputSupervisiFO = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'supervisi_fo');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputSupervisiDamri = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'supervisi_damri');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputSupervisiFacility = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'supervisi_facility');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputSupervisiHousekeeping = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', 'supervisi_housekeeping');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }

    inputnull = (id_user) => {
        const formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('divisi', '');

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/editdivisi",
            formData
        ).then(response => {
            toast.success("Berhasil diupdate");
            window.location.href = '/dashboard/divisiuser'
        })

    }




    render() {
        return(
<div className="content">
    <form>
        {/* <Row>
        <Col md={8} xs={12}>
        <Card className="card-user">
        <CardHeader>
            <h6>Input Management User</h6>
        </CardHeader>
        <CardBody>
        <Col md={12}>
        <Label for="name" required>ID User <small>/ User ID</small></Label>
        <Input type="text" value={this.state.id_user} name="name" onChange={(event) => this.setState({ id_user: event.target.value })}></Input>
        </Col>

        <Col md={12}>
        <Label for="name" required>Divisi <small>/ Divisi</small></Label>
        <Input type="text" value={this.state.divisi} name="name" onChange={(event) => this.setState({ divisi: event.target.value })}></Input>
        </Col>

        
        </CardBody>

        <Button onClick={this.inputData} color="secondary">Save</Button>
            </Card>
        </Col>

        </Row> */}
        
        <Row>
            <Col xs={12}>
            <Card>
                <CardHeader>
                <h6>User</h6>
                </CardHeader>

            <CardBody>
            <Table responsive>
                    <thead className="text-primary">
                        <tr>
                            {/* <th className="text-right">Id User</th>
                            <th className="text-right">Nama</th>
                            <th className="text-right">Divisi</th>
                            <th className="text-right">Option</th> */}
                        </tr>
                        
                    </thead>
                    <tbody>
                    {this.state.data.data && this.state.data.data.map((datas) => { 
                   return(
                    <tr>
                    {/* <td>{datas.id_user}</td> */}
                    <td>{datas.name_user}</td>
                    <td>{datas.divisi}</td>
                    <td>
  <div className="content">
   <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>

</div>
    </div>


                    </td>
                    <td><button type="button" onClick={()=>{this.inputBanquet(datas.id_user)}}>Banquet</button> | 
                    <button type="button" onClick={()=>{this.inputSecurity(datas.id_user)}}>Security</button> | 
                    <button type="button" onClick={()=>{this.inputFO(datas.id_user)}}>FO</button> |
                    <button type="button" onClick={()=>{this.inputDamri(datas.id_user)}}>Damri</button> |
                    <button type="button" onClick={()=>{this.inputHousekeeping(datas.id_user)}}>Housekeeping</button> |
                    <button type="button" onClick={()=>{this.inputFacility(datas.id_user)}}>Facility</button> |
                    <button type="button" onClick={()=>{this.inputSupervisiSecurity(datas.id_user)}}>Supervisi Security</button> | 
                    <button type="button" onClick={()=>{this.inputSupervisiBanquet(datas.id_user)}}>Supervisi Banquet</button> |
                    <button type="button" onClick={()=>{this.inputSupervisiFO(datas.id_user)}}>Supervisi FO</button> |
                    <button type="button" onClick={()=>{this.inputSupervisiDamri(datas.id_user)}}>Supervisi Damri</button> |
                    <button type="button" onClick={()=>{this.inputSupervisiHousekeeping(datas.id_user)}}>Supervisi Housekeeping</button> |
                    <button type="button" onClick={()=>{this.inputSupervisiFacility(datas.id_user)}}>Supervisi Facility</button> |
                    <button type="button" onClick={()=>{this.inputnull(datas.id_user)}}>Kosongkan</button> 
                    
                     </td>
                </tr>

                   )
                
                })}
                        
                       

                    </tbody>

             </Table>
            </CardBody>


            </Card>
            </Col>
            

        </Row>




    </form>

    </div>




        )
        
    }



}

export default DivisiUser;


