// ##############################
// // // table head data and table body data for Tables view
// #############################

const thead = ["Nama", "Country", "City", "Salary"];
const tableAbsensi = ["Nama", "Divisi", "Lokasi", "Foto Selfie", "Foto Tempat", "Waktu Absen", "Status", "Option"]
const tableQrcode = ["Location", "Date Start", "Date End", "Qr Code", "Option"]
const tableOtorisasi = ["Nama", "Divisi", "Lokasi", "Foto Selfie", "Foto Tempat", "Waktu Absen", "Status", "Supervisi", "tanggal otorisasi", "Option"]
const tableOtorisasiLog = ["Nama", "Divisi", "Lokasi", "Waktu Absen", "Status", "Option"]
const tableListUser = ["Nama", "Divisi", "Option"]
const tableCount = ["Nama", "Tanggal", "Jumlah", "Keterangan"]

const tbody = [
  {
    className: "table-success",
    data: ["Dakota Rice", "Niger", "Oud-Turnhout", "$36,738"]
  },
  {
    className: "",
    data: ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "$23,789"]
  },
  {
    className: "table-info",
    data: ["Sage Rodriguez", "Netherlands", "Baileux", "$56,142"]
  },
  {
    className: "",
    data: ["Philip Chaney", "Korea, South", "Overland Park", "$38,735"]
  },
  {
    className: "table-danger",
    data: ["Doris Greene", "Malawi", "Feldkirchen in Kärnten", "$63,542"]
  },
  { className: "", data: ["Mason Porter", "Chile", "Gloucester", "$78,615"] },
  {
    className: "table-warning",
    data: ["Jon Porter", "Portugal", "Gloucester", "$98,615"]
  }
];

// data for <thead> of table in TableList view
// data for <tbody> of table in TableList view
export { thead, tbody, tableAbsensi, tableOtorisasi, tableOtorisasiLog, tableListUser, tableCount};
