import Dashboard from "../views/Dashboard/Dashboard.jsx";
import Notifications from "../views/Notifications/Notifications.jsx";
import Icons from "../views/Icons/Icons.jsx";
import Typography from "../views/Typography/Typography.jsx";
import TableList from "../views/TableList/TableList.jsx";
import Maps from "../views/Maps/Maps.jsx";
import UserPage from "../views/UserPage/UserPage.jsx";
import SliderEditor from '../views/SliderEditor/SliderEditor';
import Products from "../views/Products/Products.jsx";
import PaymentConfrimation from "../views/PaymentConfirmation/PaymentConfirmation.jsx";
import OrderListToDeliver from "../views/OrderListToDeliver/OrderListToDeliver.jsx";
import UserPatrol from "../views/UserPatrol/UserPatrol.jsx"
import Qrcode from "../views/Qrcode/Qrcode.jsx";
import ReportSecurity from "../views/ReportSecurity/ReportSecurity.jsx";
import ReportBanquet from "../views/ReportBanquet/ReportBanquet.jsx";
import ReportFacility from "../views/ReportFacility/ReportFacility.jsx";
import ReportSecurityBlst from "../views/ReportSecurityBlst/ReportSecurityBlst.jsx";
import DivisiUser from "../views/DivisiUser/DivisiUser.jsx";
import LogUser from "../views/LogUser/LogUser.jsx";
import LogUserSecurity from "../views/LogUserSecurity/LogUserSecurity.jsx";
import LogUserBanquet from "../views/LogUserBanquet/LogUserBanquet.jsx";
import ReportFo from "../views/ReportFo/ReportFo.jsx";
import LogUserFo from "../views/LogUserFo/LogUserFo.jsx";
import ReportDamri from "../views/ReportDamri/ReportDamri.jsx";
import LogUserDamri from "../views/LogUserDamri/LogUserDamri.jsx";
import LogUserFacility from "../views/LogUserFacility/LogUserFacility.jsx";
import LogUserSecurityBlst from "../views/LogUserSecurityBlst/LogUserSecurityBlst.jsx";
import ApproveFacility from "../views/ApproveFacility/ApproveFacility.jsx";
import ListUserFacility from "../views/ListUserFacility/ListUserFacility.jsx";
import CountAbsensi from "../views/CountView/CountAbsensi.jsx";
import ReportPp from "../views/ReportPp/ReportPp.jsx";
import ReportGssi from "../views/ReportGssi/ReportGssi.jsx";


var dashRoutes = [
  // {
  //   path: "/dashboard/index",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-bank",
  //   component: Dashboard
  // },
  // {
  //   path: "/dashboard/products",
  //   name: "Product",
  //   icon: "nc-icon nc-atom",
  //   component: Products
  // },
  // {
  //   path: "/dashboard/slider",
  //   name: "Slider Front",
  //   icon: "nc-icon nc-layout-11",
  //   component: SliderEditor
  // },
  {
    path: "/dashboard/userpatrol",
    name: "User Patrol",
    icon: "nc-icon nc-money-coins",
    divisi: ['admin'],
    component: UserPatrol
  },
  {
    path: "/dashboard/loguser",
    name: "Log User",
    icon: "nc-icon nc-money-coins",
    divisi: ['admin'],
    component: LogUser
  },
  {
    path: "/dashboard/divisiuser",
    name: "Management User",
    icon: "nc-icon nc-money-coins",
    divisi: ['admin'],
    component: DivisiUser
  },
  {
    path: "/dashboard/reportsecurity",
    name: "Report Security",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_security'],
    component: ReportSecurity

  },
  {
    path: "/dashboard/logusersecurity",
    name: "Log Security",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_security'],
    component: LogUserSecurity

  },
  {
    path: "/dashboard/reportbanquet",
    name: "Report Banquet",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_banquet'],
    component: ReportBanquet

  },
  {
    path: "/dashboard/loguserbanquet",
    name: "Log Banquet",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_banquet'],
    component: LogUserBanquet
  },
  {
    path: "/dashboard/reportfo",
    name: "Report FO",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_fo'],
    component: ReportFo

  },
  {
    path: "/dashboard/reportfacility",
    name: "Report Facility",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_facility'],
    component: ReportFacility

  },
  {
    path: "/dashboard/reportsecurityblst",
    name: "Report Security BLST",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_security_blst'],
    component: ReportSecurityBlst

  },
  {
    path: "/dashboard/reportpp",
    name: "Report Absensi PP",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_pp'],
    component: ReportPp

  },

  {
    path: "/dashboard/reportgssi",
    name: "Report Absensi GSSI",
    icon: "nc-icon nc-box",
    divisi: ['s_gssi'],
    component: ReportGssi

  },

  {
    path: "/dashboard/loguserfo",
    name: "Log FO",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_fo'],
    component: LogUserFo
  },

  {
    path: "/dashboard/reportdamri",
    name: "Report Damri",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_damri'],
    component: ReportDamri

  },
  {
    path: "/dashboard/loguserdamri",
    name: "Log Damri",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_damri'],
    component: LogUserDamri
  },

  {
    path: "/dashboard/loguserfacility",
    name: "Log Facility",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_facility', 'supervisi_ga'],
    component: LogUserFacility
  },

  // {
  //   path: "/dashboard/countabsensi",
  //   name: "count Facility",
  //   icon: "nc-icon nc-box",
  //   divisi: ['supervisi_facility', 'supervisi_ga'],
  //   component: CountAbsensi
  // },

  {
    path: "/dashboard/approvefacility",
    name: "Terverifikasi",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_facility', 'supervisi_ga'],
    component: ApproveFacility
  },

  {
    path: "/dashboard/listuserfacility",
    name: "Jumlah Absensi",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_facility', 'supervisi_ga'],
    component: ListUserFacility
  },

  {
    path: "/dashboard/logusersecurityblst",
    name: "Log Security BLST",
    icon: "nc-icon nc-box",
    divisi: ['supervisi_security_blst', 'supervisi_ga'],
    component: LogUserSecurityBlst
  },
  {
    path: "/dashboard/order-to-deliver",
    name: "Absensi User",
    icon: "nc-icon nc-box",
    divisi:['tes'],
    component: OrderListToDeliver
  },
  {
    path: "/dashboard/qrcode",
    name: "QR Code",
    icon: "nc-icon nc-box",
    divisi: ['admin', 'supervisi_facility'],
    component: Qrcode
  },

  {
    path: "/dashboard/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    divisi: ['tes'],
    component: Icons
  },
  // { path: "/dashboard/maps", name: "Maps", icon: "nc-icon nc-pin-3", component: Maps },
  // {
  //   path: "/dashboard/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications
  // },
  // {
  //   path: "/dashboard/user-page",
  //   name: "User Profile",
  //   icon: "nc-icon nc-single-02",
  //   component: UserPage
  // },
  // {
  //   path: "/dashboard/tables",
  //   name: "Table List",
  //   icon: "nc-icon nc-tile-56",
  //   component: TableList
  // },
  // {
  //   path: "/dashboard/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography
  // },
  // {
  //   pro: true,
  //   path: "/dashboard/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-spaceship"
  // },  

  // { 
  //   redirect: true, 
  //   path: "/", 
  //   pathTo: "/dashboard", 
  //   name: "Dashboard" 
  // }
];
export default dashRoutes;
