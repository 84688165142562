import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Button,
    CardFooter,
    CardTitle,
    Row,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Col,
    Table
} from "reactstrap";
import { thead, tbody, tableAbsensi, tableCount } from "../../variables/general.jsx";
import Stats from "../../components/Stats/Stats.jsx";
import { Label } from '../../components/UI/Form/Label/Label.js';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { ListUser } from '../../api/index';
// import { ListPayment, ConfirmPayment, WaitingPayment,RejurnalPayment } from '../../api/index'
import { toast, ToastContainer } from 'react-toastify';
import Moment from 'react-moment';
import 'moment/locale/id';
import Axios from 'axios';
import moment from 'moment'
import { parse } from 'querystring';

class CountAbsensi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
            facility: []

        }
    }

    componentDidMount() {


      const formData = new FormData();
        formData.append('id_user', this.props.match.params.id);

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/total_task",
            formData
        ).then(response => {
                    this.setState({
                    isLoaded: true,
                    data: response.data.data
                })
          console.log(response);
        //   window.location.href = '/dashboard/qrcode'
        })


        // fetch('https://system.ipbsciencetechnopark.com/api/total_task', {id_user:this.props.match.params.id}).then(res => res.json())
        // .then(
        //     (result) => {
        //         this.setState({
        //             isLoaded: true,
        //             data: result.data
        //         })
        //     },
        //     (error) => {
        //         this.setState({
        //           isLoaded: true,
        //           error
        //         });
        //       }
        // )
    }


    // HandleUser(id_user) {
        // const formData = new FormData();
        // formData.append('id_user', id_user);

        // return Axios.post(
        //     "https://system.ipbsciencetechnopark.com/api/total_task",
        //     formData
        // ).then(response => {
        //     console.log(response);
        // //   window.location.href = '/dashboard/qrcode'
        // })

    // }

    // FetchUser(id) {
        

    //   const formData = new FormData();
    //   formData.append("id_user", '18-58-216');

    //   return Axios.post(
    //     "https://system.ipbsciencetechnopark.com/api/total_task",
    //     formData)
    //     .then(response => {
    //      console.log(response.data);
    //     })

    // }



    // HandleApprove(id) {
    //   const formData = new FormData();
    //   formData.append("id", id);
    //   formData.append("name_supervisi", localStorage.getItem('name'));

    //   return Axios.post(
    //     "https://system.ipbsciencetechnopark.com/api/approve_otorisasi",
    //     formData)
    //     .then(response => {
    //       toast.success("Approve Berhasil");
    //       window.location.href = '/dashboard/loguserfacility'
          
    //     })

    // }






render() {
    const { error, isLoaded, data } = this.state;
    
    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        return (
            <div className="content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Jumlah Absensi Facilty</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                <thead className="text-primary">
                    <tr>
                      {tableCount.map((prop, key) => {
                        if (key === tableCount.length - 1)
                          return (
                            <th key={key} className="text-right">
                              {prop}
                            </th>
                          );
                        return <th key={key}>{prop}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <td>nama</td>
                    </tr> */}
                    {data.map((datas) => { 

                      let ket;

                      if(datas.jumlah >= 6 ) {
                        ket = 'green'
                      } else {
                        ket = 'red'
                      }

                

                      let color;
                      // let date_absen = new Date(datas.date_absen)
                      // let comperesionDate = date_absen.getHours() + ':' + date_absen.getMinutes() + ':' + date_absen.getSeconds()
                      
                      // console.log(comperesionDate);
                      // console.log(datas.date_end <= comperesionDate)
                      // if (Date.parse(`01/01/2022 ${datas.date_start}`) <= Date.parse(`01/01/2022 ${comperesionDate}`) && Date.parse(`01/01/2022 ${datas.date_end}`) >= Date.parse(`01/01/2022 ${comperesionDate}`)) {
                      //   color = 'white'
                      // } else {
                      //   color = 'yellow'
                      // }
                      return (
              <tr style={{backgroundColor:ket}} key={datas.id}>
                <td>{datas.name_user}</td>
                <td>{datas.tanggal}</td>
                <td>{datas.jumlah}</td>
                {/* <td>{ket}</td> */}


                {/* <td>{datas.location}</td>  */}
                {/* <td><img src={datas.photo_selfie} alt="new"/></td>
                <td><img src={datas.photo_tempat} alt="new"/></td> */}
                {/* <td>{datas.date_absen}</td>
                <td>{datas.status_absen}</td> */}
                <td> 
                  
                  {/* <button onClick={(e) => this.HandleUser(datas.id_user)}>View</button> */}

            {/* <button onClick={(e) => this.HandleApprove(datas.id)}>Approve</button> |
             <button>Berhasil Diotorisasi</button> */}
              </td>
              </tr>
            )})}
                  </tbody>

                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
          <ul>
            
          </ul>
          </div>
        );
      }
}


}

export default CountAbsensi;